<template>
  <div class="secondHouse">
    <div class="head">
      <!-- 轮播图 -->
      <van-swipe class="my-swipe" indicator-color="#fff">
        <van-swipe-item v-for="(item, index) in swipeList" :key="index">
<!--          <img :src="../../assets/images/SecondHandHouse.png" alt="" />-->
        </van-swipe-item>
        <van-swipe-item v-if="swipeList && swipeList.length == 0">
          <img :src="require('../../assets/images/SecondHandHouse.png')" alt="" />
        </van-swipe-item>
      </van-swipe>
      <div class="icon-box">
        <div class="icon-left">
          <div class="icon-item" @click="goBack">
            <van-icon name="arrow-left" />
          </div>
        </div>
        <div class="icon-left">
          <div class="icon-item" @click="search">
            <van-icon name="search" />
          </div>
        </div>
      </div>
      <div class="middle">
        <div class="middle-head">
          <div class="title">二手房推荐</div>
        </div>
        <div class="box-content">
          <!--          <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" offset="50">-->
          <div class="box-item" v-for="item in houseList.slice(0,6)" :key="item.id">
            <house-item :item="item" />
          </div>
          <!--          </van-list>-->
        </div>

      </div>
      <!-- <div class="fixed">
        <div class="fixed-icon" @click="imgback">
          <van-icon name="wap-home-o" />
        </div>
      </div> -->

      <div class="bottom" @click="lookMore">
        <div class="bottom-more">查看更多</div>
        <img src="../../assets/images/bottom-more.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import houseItem from "../../components/HouseItem.vue";

export default {
  name: "SecondHandHouse",
  data() {
    return {
      pageNum: 0,
      pageSize: 10,
      loading: false,
      finished: false,
      swipeList: [],
      houseList: [],
    };
  },

  mounted() {
    this.getSecondHouseList();
  },

  methods: {
    // onLoad() {
    //   this.loading = true;
    //   this.pageNum++;
    //   console.log("你说")
    //   this.getSecondHouseList()
    // },

    //跳转到首页
    goBack() {
      this.$router.push("/");
    },
    //跳转到搜索页面
    search() {
      this.$router.push("/search");
    },
    //查看更多跳转到搜索页面
    lookMore() {
      let id = 1;
      this.$router.push({
        path: "/secondHouseList",
        query: {
          id: id,
        },
      });
    },
    imgback() {
      this.$router.push("/");
    },

    getSecondHouseList() {
      // const data = {
      //   pageNum:this.pageNum,
      //   pageSize: this.pageSize
      // }
      this.$post("secondHouse/getSecondHouseList").then((res) => {
        if (res.status == 200) {
          // this.loading = false;
          // this.finished =false;
          // let flag = false
          this.houseList = res.data.secondHouses;
          //   if(res.data.list.length == res.pageSize  &&
          //       res.data.count % this.pageSize == this.pageNum &&
          //       res.data.count / this.pageSize == 0){
          //     flag = true;
          //   }
          //   if(this.houseList.length >= res.data.conut || (res.data.secondHouses.length < this.pageSize || flag)){
          //     this.finished = true
          //   }
        }else {
          this.$toast.fail(res.msg);
        }
      });
    },
  },
  components: {
    houseItem,
  },
};
</script>
<style lang="less" scoped>
.secondHouse {
  // padding-bottom: 60px;
  .box-content {
    div {
      line-height: 1;
    }
    .box-item {
      // display: flex;
      padding: 10px 0 20px;
      border-bottom: 1px solid #eee;
    }
  }
  .head {
    position: relative;
    .van-swipe-item {
      img {
        width: 100%;
        vertical-align: middle;
        height: 250px;
      }
    }
  }
}
.icon-box {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 0;
  left: 0;
  right: 0;
  padding: 12px;
  .icon-item {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    .van-icon {
      color: #e5e4e2;
      font-size: 20px;
    }
  }
  .icon-right {
    display: flex;
    .icon-item {
      margin-left: 10px;
    }
  }
}
.middle {
  background-color: #fff;
  padding: 10px 15px 0;
  margin-top: 10px;
  width: 100%;
  box-sizing: border-box;
  .middle-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      color: #333;
      font-size: 16px;
      padding-left: 15px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 4px;
        height: 14px;
        border-radius: 2px;
        background-color: #09d5c6;
      }
    }
  }
}

.bottom {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  .bottom-more {
    color: #777;
    font-size: 14px;
    margin-right: 5px;
  }
  img {
    width: 15px;
    height: 15px;
    vertical-align: middle;
  }
}
.fixed {
  position: fixed;
  top: 580px;
  left: 30px;
  .fixed-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    .van-icon {
      color: rgba(0, 0, 0, 0.3);
      font-size: 24px;
    }
  }
}
</style>